import React from 'react'

function ImagesGrid(props) {
  const { images } = props

  const renderImages = (images) => {
    return images?.map((src, index) => {
      return (
        <div
          key={index}
          className={
            index === 0
              ? 'left-side'
              : index === 1
              ? 'right-side'
              : index % 3 === 0
              ? 'full-width'
              : index % 3 === 1
              ? 'right-space'
              : 'left-space'
          }
        >
          <img src={src.url} alt={`Image${index + 1}`} />
        </div>
      )
    })
  }
  return <div className="clear d-flex">{renderImages(images)}</div>
}

export default ImagesGrid
