import './App.css'
import 'bootstrap/dist/css/bootstrap.min.css'
import { Route, Routes } from 'react-router-dom'
import Index from './Pages/Index'
import i18n from './i18n'
import { Suspense, useState } from 'react'
import { ToastContainer } from 'react-toastify'
import Project from './Pages/Project'
import './index.css'
import GoogleAnalytics from './GoogleAnayltics'
import Dummy from './Pages/Dummy'


function App() {
  const [locale, setLocale] = useState(i18n.language)

  i18n.on('languageChanged', (lng) => setLocale(i18n.language))

  return (
    <div className="App">
      <ToastContainer />
      <GoogleAnalytics/>

      <Suspense>
        <Routes>
          <Route path="/" element={<Index />} />
          <Route path="/article/:id" element={<Project />} />
          <Route path="/dummy" element={<Dummy />} />
        </Routes>
      </Suspense>
    </div>
  )
}

export default App
