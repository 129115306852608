import React, { useRef } from 'react'
import { Container } from 'react-bootstrap'
import { HeroComponent } from '../Components/HeroComponent'
import { HomeControl } from '../Components/HomeControl'
import { useEffect, useState } from 'react'
import useDeltaStore from '../Store/Store'
import { I18nextProvider } from 'react-i18next'
import i18n from '../i18n'
import { adminInfo, getCountry, getPosts } from '../api'
import { useNavigate } from 'react-router-dom'

const Index = () => {
  const navigate = useNavigate()

  const mainSectionRef = useRef(null)

  const deltaRefLogo = useRef(null)

  const [colorCount, setColorCount] = useState(0)

  const [currentColor, setCurrentColor] = useState('rgba(255, 71, 46, 1)')

  const [currentText, setCurrentText] = useState('R242 g6 b203')

  const [currentIndex, setCurrentIndex] = useState('')

  const [isVideoActive, setIsVideoActive] = useState(false)

  const [isActive, setIsActive] = useState(false)

  const [heroText, setHeroText] = useState(false)

  const [heroKey, setHeroKey] = useState('')

  const [backgroundimage, setBackgroundImage] = useState('')

  const [video, setVideo] = useState([])

  const [currentVideoHome, setCurrentVideoHome] = useState('')

  const [currentVideoName, setCurrentVideoName] = useState('')

  const [currentLanguage, setCurrentLanguage] = useState('')

  const [currentPage, setCurrentPage] = useState(1)

  const [thumbnailKeys, setThumbnailKeys] = useState(false)

  const [selectedTab, setSelectedTab] = useState(false)

  const [firstAnimation, setFirstAnimation] = useState(true)

  const [isHeaderTextActive, setIsHeaderTextActive] = useState(false)

  const [heroHeight, setHeroHeight] = useState(0)

  const [logoInfoDistance, setLogoInfoDistance] = useState(0)

  const currentLanguageLocal = localStorage.getItem('i18nextLng')

  const [webData, setWebData] = useState({
    mail: '',
    address: '',
    lat: 55.48718590405666,
    lng: 10.216327843782562,
    instagramLink: '',
    contact: '',
    mailLink: '',
    addressda: '',
    instagram: '',
    footerLeft: '',
    footerRight: '',
    field_company_header: '',
    companyHeader:'',
    companyHeaderDa:''
  })

  const openText = () => {
    setHeroText(true)
  }

  const colour = [
    'rgba(255, 71, 46, 1)',
    'rgba(0, 81, 239, 1)',
    'rgba(221, 252, 34, 1)',
    'rgba(79, 237, 75, 1)',
  ]

  const text = ['R242 g6 b203', 'R0 G81 B239', 'R221 G252 B34', 'R79 G237 B75']

  const bgimages = ['one.png', 'two.png', 'three.png', 'four.png']

  const currentCount = parseInt(localStorage.getItem('colorCount')) || 0

  const changeThemeColor = () => {
    if (currentCount < colour.length - 1) {
      const newCount = currentCount + 1

      localStorage.setItem('colorCount', newCount)

      setColorCount(newCount)
    } else {
      localStorage.setItem('colorCount', 0)
      setColorCount(0)
    }

    setIsVideoActive(false)
    setIsHeaderTextActive(false)
  }

  useEffect(() => {
    let setColor = colour[currentCount]

    let setText = text[currentCount]

    let setImage = bgimages[currentCount]

    setCurrentText(setText)

    setCurrentColor(setColor)

    setBackgroundImage(setImage)

    setHeroKey(Math.random())
  }, [colorCount])

  const preloadIframeContent = (src) => {
    const iframe = document.createElement('iframe');
    iframe.src = src;
    iframe.style.display = 'none';
    document.body.appendChild(iframe);

    setTimeout(() => {
      document.body.removeChild(iframe);
    }, 1000); 
  };
  

  useEffect(() => {
    const fetchData = async () => {
      try {
        const postresponse = await getPosts()

        const adminresponse = await adminInfo()

        const postData = postresponse.data.data

        const adminData = adminresponse.data.data

        setWebData({
          ...webData,
          mail: adminData.field_email,
          mailLink: adminData.field_mail_link,
          instagramLink: adminData.field_instagram_link,
          contact: adminData.field_contact,
          linkedinLink: adminData.field_linked_in_link,
          lat: adminData.field_lat,
          lng: adminData.field_lng,
          address: adminData.field_address,
          addressda: adminData.field_address_da,
          instagram: adminData.field_instagram,
          footerLeft: adminData.field_left_control,
          footerRight: adminData.field_right_control,
          companyHeader: adminData.field_company_header,
          companyHeaderDa: adminData.field_company_header_da,
          services: adminData.field_services_list,
          clients: adminData.field_clients,
          servicesDa: adminData.field_services_da,
          clientsDa: adminData.field_client_da,
        })
        setVideo(postData)
      } catch (err) {
        console.log(err)
      }
    }
    fetchData()
  }, [])

  const setIndex = (index, videoLink, videoname, videonameda) => {
    if (isDesktop()) {
      setIsHeaderTextActive(false)

      setCurrentIndex(index)

      setIsVideoActive(true)

      preloadIframeContent(videoLink)
      
      setCurrentVideoHome(videoLink)
      if (currentLanguageLocal === 'da') {
        setCurrentVideoName(videonameda)
      } else {
        setCurrentVideoName(videoname)
      }

      // setTimeout(() => {
      setIsHeaderTextActive(true)
      // }, 2500)
    }
  }

  const removeIndex = () => {
    setCurrentIndex('')
  }

  const storeCurrentWidth = useDeltaStore((state) => state.storeCurrentWidth)

  const storeCurrentHeight = useDeltaStore((state) => state.storeCurrentHeight)

  const handleResize = () => {
    const width = window.innerWidth

    const height = window.innerHeight

    storeCurrentWidth(width)

    storeCurrentHeight(height)
  }

  useEffect(() => {
    if (typeof window !== 'undefined') {
      storeCurrentWidth(window.innerWidth)

      storeCurrentHeight(window.innerHeight)

      const height = window.innerHeight - mainSectionRef.current.clientHeight

      const deltaLogo =
        mainSectionRef.current.clientHeight + deltaRefLogo.current.clientHeight

      setLogoInfoDistance((window.innerHeight - deltaLogo - 64) / 1.6)

      setHeroHeight(height)

      window.addEventListener('resize', handleResize)

      return () => {
        window.removeEventListener('resize', handleResize)
      }
    }
  }, [])

  const width = useDeltaStore((state) => state.windowWidth)

  const isDesktop = () => {
    const userAgent = navigator.userAgent
    return !/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      userAgent
    )
  }

  useEffect(() => {
    setTimeout(() => {
      setFirstAnimation(false)
    }, 3000)
  }, [firstAnimation])

  const handleRoute = (videoId, id, index) => {
    navigate(`/article${videoId}`)

    localStorage.setItem('videoId', id)

    localStorage.setItem('videoIndex', index)
  }

  const handleLanguageChange = (lang) => {
    setCurrentLanguage(lang)

    i18n.changeLanguage(lang)

    setFirstAnimation(true)

    setHeroKey(Math.random())
  }

  const handlePageClick = (page) => {
    setCurrentPage(page)

    if (page !== currentPage) {
      setThumbnailKeys(Math.random())

      setSelectedTab(true)
    }
  }

  useEffect(() => {
    i18n.changeLanguage(currentLanguage)

    const getCountryFunction = async () => {
      const getCountryResponse = await getCountry()

      const countryName = getCountryResponse?.data.location?.country?.name

      if (
        countryName === 'Sweden' ||
        countryName === 'Iceland' ||
        countryName === 'Germany' ||
        countryName === 'Norway'
      ) {
        i18n.changeLanguage('da')
      } else {
        setCurrentLanguage(currentLanguage)
      }
    }
    getCountryFunction()
  }, [])

  const handleScrollAnimation = () => {
    return
  }

  return (
    <I18nextProvider i18n={i18n}>
      <Container
        className={`p-0 g-0 home_section_container animated_project_hero overflow-hidden`}
        fluid
      >
        <HeroComponent
          activeColor={currentColor}
          isVideoActive={isVideoActive}
          isActive={isActive}
          setactive={() => setIsActive(true)}
          removeActive={() => setIsActive(false)}
          position={'absolute'}
          heroText={heroText}
          hideHeroText={() => setHeroText(false)}
          openText={openText}
          currentText={currentText}
          changeThemeColor={changeThemeColor}
          tab={'home'}
          keys={heroKey}
          width={width}
          currentVideoHome={currentVideoHome}
          currentVideoName={currentVideoName}
          firstAnimation={firstAnimation}
          headerText={isHeaderTextActive}
          heroInfoText={webData}
          triggerScrollAnimation={handleScrollAnimation}
          height={heroHeight}
          deltaRefLogo={deltaRefLogo}
          logoInfoDistance={logoInfoDistance}
        />

        <HomeControl
          changeThemeColor={changeThemeColor}
          activeColor={currentColor}
          currentText={currentText}
          currentIndex={currentIndex}
          setIndex={setIndex}
          removeIndex={removeIndex}
          handleRoute={handleRoute}
          currentbackgroundimage={backgroundimage}
          video={video}
          width={width}
          handleLanguageChange={handleLanguageChange}
          handlePageClick={handlePageClick}
          currentPage={currentPage}
          setThumbnailKeys={thumbnailKeys}
          selectedTab={selectedTab}
          firstAnimation={firstAnimation}
          heroInfoText={webData}
          mainSectionRef={mainSectionRef}
        />
      </Container>
    </I18nextProvider>
  )
}

export default Index
