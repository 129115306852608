import { useEffect } from 'react'

const GoogleAnalytics = () => {
  useEffect(() => {
    // Initialize Google Analytics
    window.dataLayer = window.dataLayer || []
    function gtag() {
      window.dataLayer.push(arguments)
    }
    gtag('js', new Date())
    gtag('config', 'G-384Y64X5GY') // Replace with your actual tracking ID
  }, [])

  return null // Google Analytics doesn't need to render anything in the UI
}

export default GoogleAnalytics

// GTM-M7R7XBXK
