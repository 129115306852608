import { create } from 'zustand'

const useDeltaStore = create((set) => ({
  windowWidth: 1800,
  windowHeight: 800,
  currentVideoIndex:'',
  storeCurrentWidth: (width) => set({windowWidth:width}),
  storeCurrentHeight: (height) => set({windowHeight:height}),
  storeCurrentVideoIndex: (index) => set({currentVideoIndex:index}),
}))


export default useDeltaStore