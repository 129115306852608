import React from 'react'
import { styled } from 'styled-components'
import { useTranslation } from 'react-i18next'

const SmallScreenInfo = styled.div`
  display: flex;
  width: 200px;
  justify-content: space-between;
  margin-bottom: 10px;
`
const SmallText = styled.p`
  font-size: 10px;
  line-height: 12px;
  margin-bottom: 2px !important;
  font-weight: 500;
`
function List2(props) {
  const { hideHeroText, width, heroInfoText } = props

  const { t, i18n } = useTranslation()

  const currentLanguage = i18n.language

  const clientsData =
    currentLanguage === 'da'
      ? heroInfoText.clientsDa.slice(1)
      : heroInfoText.clients.slice(1)

  const servicesData =
    currentLanguage === 'da'
      ? heroInfoText.servicesDa.slice(1)
      : heroInfoText.services.slice(1)

  const clientFirstIndex =
    currentLanguage === 'da'
      ? heroInfoText.clientsDa[0].value
      : heroInfoText.clients[0].value

  const servicesFirstIndex =
    currentLanguage === 'da'
      ? heroInfoText.servicesDa[0].value
      : heroInfoText.services[0].value


  return (
    <>
      <SmallScreenInfo className={width < 672 ? 'mt-2' : ''}>
        {' '}
        <div className="w-50">
          <SmallText>{t('MAIL')}</SmallText>
        </div>
        <div className="w-50">
          <SmallText onClick={() => window.open(`${heroInfoText?.mailLink}`)}>
            {' '}
            {heroInfoText?.mail}
          </SmallText>
        </div>
      </SmallScreenInfo>

      <SmallScreenInfo className={width < 672 ? 'mt-2' : ''}>
        {' '}
        <div className="w-50">
          <SmallText>{t('CONTACT')}</SmallText>
        </div>
        <div className="w-50">
          <SmallText>{heroInfoText?.contact}</SmallText>
        </div>
      </SmallScreenInfo>

      <SmallScreenInfo className={width < 672 ? 'mt-2' : ''}>
        {' '}
        <div className="w-50">
          <SmallText>{t('ADDRESS')}</SmallText>
        </div>
        <div className="w-50">
          <SmallText
            onClick={() =>
              window.open(
                `https://www.google.com/maps/place/DELTA+PRODUCTIONS+ApS/@55.675697,12.5941202,21z/data=!4m6!3m5!1s0x46525376e2406ecb:0x330ed93538ead0f8!8m2!3d55.6757197!4d12.5941303!16s%2Fg%2F11k9z6ghtf?entry=ttu`,
                '_blank'
              )
            }
          >
            {currentLanguage === 'da'
              ? heroInfoText?.addressda
              : heroInfoText?.address}
          </SmallText>
        </div>
      </SmallScreenInfo>

      <SmallScreenInfo className={width < 672 ? 'mt-2' : ''}>
        {' '}
        <div className="w-50">
          <SmallText>{servicesFirstIndex}</SmallText>
        </div>
        <div className="w-50">
          {servicesData.map((curr) => {
            return (
              <>
                <SmallText>{curr.value}</SmallText>
              </>
            )
          })}
          {/* <SmallText>Visual Identity Graphic Design Development</SmallText>} */}
        </div>
      </SmallScreenInfo>

      <SmallScreenInfo className={width < 672 ? 'mt-2' : ''}>
        {' '}
        <div className="w-50">
          <SmallText>{clientFirstIndex}</SmallText>
        </div>
        <div className="w-50">
          {clientsData.map((curr) => {
            return (
              <>
                <SmallText>{curr.value}</SmallText>
              </>
            )
          })}
          {/* <SmallText>Gyldendals</SmallText>
          <SmallText>Rec Watches</SmallText>
          <SmallText>NOMA</SmallText>
          <SmallText>Empirical spirits</SmallText>
          <SmallText>Spacesavers</SmallText>
          <SmallText>ØRSTED</SmallText>
          <SmallText>SPACE 10</SmallText>
          <SmallText>Elmgreen & Dragset</SmallText>
          <SmallText>Tue Greenfort</SmallText>
          <SmallText>Lea Porsager</SmallText>
          <SmallText>HAXI</SmallText> */}
        </div>
      </SmallScreenInfo>

      <SmallScreenInfo className={width < 672 ? 'mt-2' : ''}>
        <div className="w-50">
          <SmallText>{t('FOLLOW')}</SmallText>
        </div>
        <div className="w-50">
          <SmallText
            onClick={() => window.open(`${heroInfoText?.instagramLink}`)}
          >
            INSTAGRAM
          </SmallText>
          <SmallText
            onClick={() => window.open(`${heroInfoText?.linkedinLink}`)}
          >
            LINKEDIN
          </SmallText>
        </div>
      </SmallScreenInfo>

      <SmallScreenInfo className={width < 672 ? 'mt-3' : ''}>
        <div className="w-50">
          <SmallText onClick={hideHeroText}>[{t('READ LESS')}]</SmallText>
        </div>
      </SmallScreenInfo>
    </>
  )
}

export default List2
