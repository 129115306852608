// const heightSetter = (h, innerHeight) => {
//     console.log('heightSetter', h, innerHeight)
//     if (h < innerHeight) {
//       // alert('yes')
//       setTopBottomHeight(innerHeight - h - 100)

//       const imageBoxHeight = innerHeight - h - 100

//       if (imagesBoxRef.current) {
//         imagesBoxRef.current.style.height = `${imageBoxHeight}px`
//       }
//     }
//   }

export default function scrollTo(element, duration) {
  var e = document.documentElement

  if (e.scrollTop === 0) {
    var t = e.scrollTop
    ++e.scrollTop
    e = t + 1 === e.scrollTop-- ? e : document.body
  }
  scrollToC(e, e.scrollTop, element, duration)
}

function scrollToC(element, from, to, duration) {
  if (duration <= 0) return
  if (typeof from === 'object') from = from.offsetTop
  if (typeof to === 'object') to = to.offsetTop

  scrollToX(element, from, to, 0, 1 / duration, 20, easeOutCuaic)
}
let array = []
function scrollToX(element, xFrom, xTo, t01, speed, step, motion) {
  if (t01 < 0 || t01 > 1 || speed <= 0) {
    element.scrollTop = xTo
    console.log(array)
    setTimeout(() => {
      // navigate(`/`)
    }, 1000)
    return
  }
  element.scrollTop = xFrom - (xFrom - xTo) * motion(t01)
  array.push({
    element: element,
    time: t01,
    xFrom: xFrom,
    xTo: xTo,
    t01: t01,
    speed: speed,
    motion: motion,
  })
  t01 += speed * step
  requestAnimationFrame(function () {
    scrollToX(element, xFrom, xTo, t01, speed, step, motion)
  })
}

function easeOutCuaic(t) {
  console.log('time=>', t)
  t--
  return t * t * t + 1
}
