import React, { useEffect, useState } from 'react'
import { styled } from 'styled-components'
import { Col, Row } from 'react-bootstrap'
import { ThumbailImages } from './ThumbailImages'
import { useTranslation } from 'react-i18next'

const SmallTextWhite = styled.p`
    font-size: 10px;
    line-height: 12px;
    margin-bottom: 0px !important;
    color: white;
    font-weight: 500;
`
const LanguageControl = styled.span`
    cursor: url('/Δ (1).png'), auto;
    font-size: 10px;
    line-height: 12px;
    margin-bottom: 0px !important;
    font-weight: 500;
    color: ${(props) => props.$currentcolor || 'white'};
`

const FooterLeftBox = styled.div`
    // display: flex;
    // justify-content: space-between;
    // width: 385px;
    // @media (min-width: 0px) and (max-width: 423px) {
    //     width: 80px;
    // }
    // @media (min-width: 424px) and (max-width: 767px) {
    //     width: 300px;
    // }
    // @media (min-width: 768px) and (max-width: 1024px) {
    //     width: 290px;
    // }
`

const MainSection = styled.div`
    background: #16161D;
    padding: 15px 12px;
`

const SelectedVideos = styled.div`
    display: flex;

    // @media (min-width: 768px) and (max-width: 1439px) {
    //     width: 290px;
    // }
    // @media (min-width: 768px) and (max-width: 1024px) {
    //     width: 290px;
    // }
    @media (min-width: 1441px) and (max-width: 1440px) {
        width: 385px;
    }
`

const VideosPage = styled.div`
    display: flex;
    cursor: url('/Δ (1).png'), auto;
`

const FooterContainer = styled.div`
    display: flex;
    justify-content: space-between;
    margin-top: 40px;
    @media (min-width: 0px) and (max-width: 767px) {
        margin-top: 65px;
    }
    width: 100%;
    bottom: 10px;
`
const ColorDot = styled.span`
    height: 6px;
    width: 6px;
    background: ${(props) => props.$currentcolor || 'rgba(255, 71, 46, 1)'};
    border-radius: 50%;
    display: inline-block;
    margin-right: 2px;
`
const RightText = styled.div`
    padding-right: 20px;
    display: flex;
    align-items: center;
    width: 25%;
    justify-content: space-between;

    @media (min-width: 0px) and (max-width: 423px) {
        width: 100px;
    }
    @media (min-width: 424px) and (max-width: 576px) {
        width: 120px;
    }
    @media (min-width: 577px) and (max-width: 768px) {
        width: 220px;
    }
    @media (min-width: 768px) and (max-width: 1439px) {
        width: 290px;
    }
    @media (min-width: 0px) and (max-width: 672px) {
        justify-content: flex-end;
    }
`

const ColorBox = styled.div`
    cursor: url('/Δ (1).png'), auto;
`

const ThumbnailBox = styled.div`
    min-height: 275px;
    @media (min-width: 0px) and (max-width: 671px) {
        min-height: 610px;
    }
    @media (min-width: 672px) and (max-width: 767px) {
        min-height: 520px;
    }
    @media (min-width: 768px) and (max-width: 1031px) {
        min-height: 290px;
    }
    @media (min-width: 1032px) and (max-width: 1540px) {
        min-height: 250px;
    }
`

const FooterTextBox = styled.div`
    // @media (min-width: 0px) and (max-width: 767px) {
    //     width: 360px;
    // }
`

export const HomeControl = (props) => {
    const {
        changeThemeColor,
        activeColor,
        currentText,
        handleRoute,
        setIndex,
        removeIndex,
        currentIndex,
        currentbackgroundimage,
        video,
        width,
        handleLanguageChange,
        handlePageClick,
        currentPage,
        setThumbnailKeys,
        selectedTab,
        firstAnimation,
        heroInfoText,
        mainSectionRef
    } = props

    const [chunkSize, setChunkSize] = useState(4)

    const [rowLength, setRowLength] = useState('')

    const [isSelectedText, setIsSelectedText] = useState('')

    const { t, i18n } = useTranslation()

    useEffect(() => {
        if (width > 992) {
            setChunkSize(8)

            setRowLength(3)
        } else if (width < 991 && width > 767) {
            setChunkSize(6)

            setRowLength(2)
        } else if (width < 768 && width > 672) {
            setChunkSize(6)
        } else if (width < 671) {
            setChunkSize(8)

            setRowLength(1)
        }
    }, [width])

    let itemsPerPage = chunkSize

    const startIndex = (currentPage - 1) * itemsPerPage

    const endIndex = startIndex + itemsPerPage

    const slicedItems = video?.slice(startIndex, endIndex)

    const totalPages = Math.ceil(video?.length / itemsPerPage)

    const currentLanguage = localStorage.getItem('i18nextLng')

    return (
        <>
            <MainSection ref={mainSectionRef}>
                {width > 1032 ? (
                    <SelectedVideos
                        className={`${
                            firstAnimation ? 'animate animated_entrance_selected_projects row delay-02' : ''
                        }`}
                    >
                        <div className="p-0 py-0 col-lg-3 col-6 col-md-4 custom-xl-3">
                            <SmallTextWhite>{t('SELECTED PROJECTS')}</SmallTextWhite>
                        </div>
                        <VideosPage className=" col-lg-3 col-6 col-md-4 custom-xl-3">
                            {Array.from({ length: totalPages }, (_, index) => {
                                const startPage = index * itemsPerPage + 1
                                let endPage = (index + 1) * itemsPerPage
                                if (endPage > video?.length) {
                                    endPage = video?.length
                                }
                                if (index + 1 === currentPage) {
                                }
                                return (
                                    <SmallTextWhite
                                        key={index}
                                        onClick={() => handlePageClick(index + 1)}
                                        className="pe-2"
                                        style={{ color: `${index + 1 === currentPage ? 'white' : '#A1A1A4'}` }}
                                    >
                                        {`[${startPage}-${endPage}]`}
                                    </SmallTextWhite>
                                )
                            })}
                        </VideosPage>
                    </SelectedVideos>
                ) : width > 673 && width < 1031 ? (
                    <>
                        <SelectedVideos
                            className={`${
                                firstAnimation ? 'animate animated_entrance_selected_projects row delay-02' : ''
                            }`}
                        >
                            <div className="p-0 py-0 col-lg-3 col-6 col-md-4 custom-xl-3">
                                <SmallTextWhite>{t('SELECTED PROJECTS')}</SmallTextWhite>
                            </div>
                            <VideosPage className="p-0 py-0 col-lg-3 col-6 col-md-4 custom-xl-3">
                                {Array.from({ length: totalPages }, (_, index) => {
                                    const startPage = index * itemsPerPage + 1
                                    let endPage = (index + 1) * itemsPerPage
                                    if (endPage > video?.length) {
                                        endPage = video?.length
                                    }
                                    return index === 0 ? (
                                        <>
                                            <SmallTextWhite
                                                key={index}
                                                onClick={() => handlePageClick(index + 1)}
                                                className=""
                                                style={{ color: `${index + 1 === currentPage ? 'white' : '#A1A1A4'}` }}
                                            >
                                                [{`${startPage}-${endPage}`}]
                                            </SmallTextWhite>
                                        </>
                                    ) : (
                                        <React.Fragment key={index}></React.Fragment>
                                    )
                                })}
                            </VideosPage>
                        </SelectedVideos>
                    </>
                ) : (
                    <>
                        {' '}
                        <div
                            className={`${
                                firstAnimation ? 'animate animated_entrance_selected_projects delay-02' : ''
                            }`}
                        >
                            <VideosPage>
                                {Array.from({ length: totalPages }, (_, index) => {
                                    const startPage = index * itemsPerPage + 1
                                    let endPage = (index + 1) * itemsPerPage
                                    if (endPage > video?.length) {
                                        endPage = video?.length
                                    }
                                    return index === 0 ? (
                                        <>
                                            <div key={index}>
                                                <SmallTextWhite>
                                                    {index + 1 === currentPage
                                                        ? t('SELECTED PROJECTS')
                                                        : t('SHOW PROJECTS')}
                                                </SmallTextWhite>
                                            </div>
                                            <SmallTextWhite
                                                key={index}
                                                onClick={() => handlePageClick(index + 1)}
                                                className="px-2"
                                                style={{ color: `${index + 1 === currentPage ? 'white' : '#A1A1A4'}` }}
                                            >
                                                [{`${startPage}-${endPage}`}]
                                            </SmallTextWhite>
                                        </>
                                    ) : (
                                        <React.Fragment key={index}></React.Fragment>
                                    )
                                })}
                            </VideosPage>
                        </div>
                    </>
                )}

                <ThumbnailBox>
                    <div className="mt-3 ">
                        <ThumbailImages
                            handleRoute={handleRoute}
                            setIndex={setIndex}
                            removeIndex={removeIndex}
                            currentIndex={currentIndex}
                            currentbackgroundimage={currentbackgroundimage}
                            result={slicedItems}
                            width={width}
                            startindex={startIndex}
                            setKeys={setThumbnailKeys}
                            selectedTab={selectedTab}
                            rowLength={rowLength}
                        />
                    </div>
                </ThumbnailBox>

                {width < 1032 && (
                    <>
                        {' '}
                        <SelectedVideos
                            className={`${
                                firstAnimation ? 'animate animated_entrance_selected_projects delay-02' : ''
                            }`}
                        >
                            <VideosPage>
                                {Array.from({ length: totalPages }, (_, index) => {
                                    const startPage = index * itemsPerPage + 1
                                    let endPage = (index + 1) * itemsPerPage
                                    if (endPage > video?.length) {
                                        endPage = video?.length
                                    }

                                    return index === 1 ? (
                                        <SmallTextWhite
                                            key={index}
                                            onClick={() => handlePageClick(index + 1)}
                                            style={{ color: `${index + 1 === currentPage ? 'white' : '#A1A1A4'}` }}
                                        >
                                            [{index + 1 === currentPage ? t('SELECTED PROJECTS') : t('SHOW PROJECTS')}
                                            {` ${startPage}-${endPage}`}]
                                        </SmallTextWhite>
                                    ) : (
                                        <React.Fragment key={index}></React.Fragment>
                                    )
                                })}
                            </VideosPage>
                        </SelectedVideos>
                    </>
                )}

                <FooterContainer className="row align-items-center">
                    {width > 768 ? (
                        <>
                            <Col className="row justify-content-between" sm={10} md={8} lg={9}>
                                <Col className="d-flex p-0" lg={8} sm={8}>
                                    <Col lg={6} sm={6} md={9}  className="custom-xxl-3">
                                        <LanguageControl onClick={() => window.open(`${heroInfoText?.instagramLink}`)}>
                                            [IG]
                                        </LanguageControl>{' '}
                                        <LanguageControl
                                            className="px-2"
                                            onClick={() => window.open(`${heroInfoText?.linkedinLink}`)}
                                        >
                                            [LI]
                                        </LanguageControl>
                                    </Col>

                                    <Col lg={4} sm={4} md={3}  className="custom-xxl-3">
                                        <LanguageControl
                                            onClick={() => handleLanguageChange('da')}
                                            $currentcolor={currentLanguage === 'da' ? 'white' : 'gray'}
                                        >
                                            [DK]
                                        </LanguageControl>{' '}
                                        <LanguageControl
                                            className="px-2"
                                            onClick={() => handleLanguageChange('en')}
                                            $currentcolor={currentLanguage === 'en' ? 'white' : 'gray'}
                                        >
                                            [EN]
                                        </LanguageControl>
                                    </Col>
                                </Col>
                            </Col>
                            <Col sm={2} md={4} lg={3} className="p-0 d-flex justify-content-between">
                                <div className='year_box'>
                                    <SmallTextWhite>[c] 2023</SmallTextWhite>
                                </div>

                                <ColorBox onClick={changeThemeColor}>
                                    <SmallTextWhite>
                                        <ColorDot $currentcolor={activeColor}></ColorDot> {currentText}
                                    </SmallTextWhite>
                                </ColorBox>
                            </Col>
                        </>
                    ) : width > 672 && width < 768 ? (
                        <>
                            <Col className="p-0 justify-content-between" sm={6}>
                                <div sm={6} className="p-0 d-flex ">
                                    <SmallTextWhite onClick={() => window.open(`${heroInfoText?.instagramLink}`)}>
                                        [Instagram]
                                    </SmallTextWhite>{' '}
                                    <SmallTextWhite
                                        className="px-2"
                                        onClick={() => window.open(`${heroInfoText?.linkedinLink}`)}
                                    >
                                        [LINKEDIN]
                                    </SmallTextWhite>
                                </div>
                            </Col>

                            <Col className="p-0" sm={6}>
                                <Row>
                                    <Col sm={5} className=" p-0 ">
                                        <SmallTextWhite>
                                            <span className="pe-2" onClick={() => handleLanguageChange('da')}>
                                                [DK]
                                            </span>

                                            <span onClick={() => handleLanguageChange('en')}>[EN]</span>
                                        </SmallTextWhite>
                                    </Col>

                                    <Col sm={7} className="d-flex justify-content-between p-0 ">
                                        <div>
                                            <SmallTextWhite>[c] 2023</SmallTextWhite>
                                        </div>

                                        <ColorBox onClick={changeThemeColor} className="pe-4">
                                            <SmallTextWhite>
                                                <ColorDot $currentcolor={activeColor}></ColorDot> {currentText}
                                            </SmallTextWhite>
                                        </ColorBox>
                                    </Col>
                                </Row>
                            </Col>
                        </>
                    ) : (
                        <>
                            <Col className="p-0 justify-content-between" xs={6} sm={6}>
                                <div className="p-0 d-flex ">
                                    <SmallTextWhite onClick={() => window.open(`${heroInfoText?.instagramLink}`)}>
                                        [Instagram]
                                    </SmallTextWhite>{' '}
                                    <SmallTextWhite
                                        className="px-2"
                                        onClick={() => window.open(`${heroInfoText?.linkedinLink}`)}
                                    >
                                        [LINKEDIN]
                                    </SmallTextWhite>
                                </div>
                            </Col>

                            <Col className="p-0" xs={6} sm={6}>
                                <Row>
                                    <Col xs={7} sm={7} className="p-0 ">
                                        <SmallTextWhite>
                                            {currentLanguage !== 'da' ? (
                                                <span onClick={() => handleLanguageChange('da')}>[DK]</span>
                                            ) : (
                                                <span onClick={() => handleLanguageChange('en')}>[EN]</span>
                                            )}
                                        </SmallTextWhite>
                                    </Col>

                                    <Col xs={5} sm={5} className="d-flex justify-content-between p-0 ">
                                        <div >
                                            <SmallTextWhite>[c] 2023</SmallTextWhite>
                                        </div>

                                        {/* <ColorBox onClick={changeThemeColor} className='pe-4'>
                                        <SmallTextWhite>
                                            <ColorDot $currentcolor={activeColor}></ColorDot> {currentText}
                                        </SmallTextWhite>
                                    </ColorBox> */}
                                    </Col>
                                </Row>
                            </Col>
                        </>
                    )}
                </FooterContainer>
            </MainSection>
        </>
    )
}
