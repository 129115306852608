import React from 'react'
import styled from 'styled-components'
import LazyVideoPlayer from '../Components/videoplay/LazyLoadPlayer'
import { Col, Row } from 'react-bootstrap'
import { PlayIcon } from '../Icons/PlayIcon'
import { useTranslation } from 'react-i18next'

const VideoSection = styled.div`
  cursor: url('/Δ (1).png'), auto;
  padding: 0px 10px;
`
const SmallTextWhite = styled.p`
  font-size: 10px;
  line-height: 12px;
  margin-bottom: 0px !important;
  color: white;
  font-weight: 500;
  height: 60%;
`

const SmallTextBox=styled.div`
font-size: 10px;
line-height: 12px;
margin-bottom: 0px !important;
color: white;
font-weight: 500;
height: 60%;
`

const MediumTextWhiteHover = styled.div`
  font-size: 20px;
  font-weight: 500;
  color: white;
  margin-bottom: 0px !important;

  @media (min-width: 0px) and (max-width: 480px) {
    font-size: 20px;
    width: 100%;
  }
  @media (min-width: 481px) and (max-width: 767px) {
    font-size: 14px;
  }
  @media (min-width: 767px) and (max-width: 1031px) {
    font-size: 16px;
  }
  @media (min-width: 1032px) and (max-width: 1439px) {
    font-size: 18px;
  }
  @media (min-width: 1439px) {
    font-size: 20px;
  }
  transition: color 0.3s ease;
  color: ${(props) => (props.$transition === 'true' ? '#A1A1A4' : '')};
`
const MediumTextWhite = styled.p`
  font-size: 20px;
  font-weight: 500;
  color: white;
  margin-bottom: 0px !important;

  @media (min-width: 0px) and (max-width: 480px) {
    font-size: 20px;
    width: 100%;
  }
  @media (min-width: 481px) and (max-width: 767px) {
    font-size: 14px;
  }
  @media (min-width: 768px) and (max-width: 1031px) {
    font-size: 16px;
  }
  @media (min-width: 1032px) and (max-width: 1439px) {
    font-size: 18px;
  }
  @media (min-width: 1439px) {
    font-size: 20px;
  }
`

const VideoName = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  @media (min-width: 0px) and (max-width: 480px) {
    width: 100%;
  }
  @media (min-width: 480px) and (max-width: 767px) {
    width: 100%;
  }
  @media (min-width: 768px) and (max-width: 1200px) {
    width: 100%;
  }
  @media (min-width: 1201px) and (max-width: 1540px) {
    width: 80%;
  }
  @media (min-width: 1540px) {
    width: 85%;
  }
`
const VideoInformation = styled.div`
  margin-top: 2px;
  padding: 10px 10px;
  overflow:hidden;
`
const VideoInformationSmall = styled.div`
  padding: 20px 10px;
`
const VideoText = styled.div`
  margin-top: 65px;
  text-wrap: wrap;
  overflow: hidden;
  width: 100%;
  @media (min-width: 1439px) {
    width: 33%;
  }
`
const VideoNameLeft = styled.div`
  display: flex;
  justify-content: space-between;
  width: 400px;
  word-wrap: break-word;
  @media (min-width: 0px) and (max-width: 480px) {
    width: calc(100% - 1%);
  }
  @media (min-width: 481px) and (max-width: 767px) {
    width: 300px;
  }
  @media (min-width: 768px) and (max-width: 1031px) {
    width: 550px;
  }
  @media (min-width: 1032px) and (max-width: 1439px) {
    width: 500px;
  }
  @media (min-width: 1439px) {
    width: 750px;
  }
`

const ControlBox = styled.div`
  display: flex;
  align-items: center;
`
const PlayText = styled.div`
  height: 32px;
  padding-left: 10px;
  @media (min-width: 471px) and (max-width: 767px) {
    height: 24px;
  }
  @media (min-width: 768px) and (max-width: 1430px) {
    height: 26px;
  }
  transition: color 0.3s ease;
  color: ${(props) => (props.$transition === 'true' ? '#A1A1A4' : '')};
`

const ControlContainer = styled.div`
  cursor: url('/Δ (1).png'), auto;
`
const PlayBox = styled.div`
  background: ${(props) => props.$currentcolor || 'rgba(255, 71, 46, 1)'};
  width: 23px;
  height: 23px;
  border-radius: 50%;
  padding: 2px;
  position: relative;
  transition: background-color 0.5s ease;
  background: ${(props) => (props.$transition === 'true' ? '#A1A1A4' : '')};
`
const VideoBox = (props) => {
  const {
    width,
    videoInfoLgRef,
    handlePlayClick,
    handleMouseOver,
    handleMouseLeave,
    videoDuration,
    formatDuration,
    videoIndex,
    handleVideoReady,
    currentVideo,
    videoBoxRef,
    videoWidth,
    videoHeight,
    isPlay,
    playerRef,
    handleVideoEnd,
    handleDuration,
    handlePlay,
    isVideoLoaded,
    controlRef,
    currentColor,
    hover,
    currentRef,
    targetDivRef,
    targetDivSmallScreenRef,
  } = props

  const { t } = useTranslation()

  const currentLanguage = localStorage.getItem('i18nextLng')

  // const [distanceFromBottom, setDistanceFromBottom] = useState(0)
  // const targetDivRef = useRef(null)

  // useEffect(() => {
  //     const handleResize = () => {
  //         calculateDistance()
  //     }

  //     const calculateDistance = () => {
  //         if (targetDivRef.current) {
  //             const rect = targetDivRef.current.getBoundingClientRect()
  //             const distance = window.innerHeight - rect.bottom
  //             setDistanceFromBottom(distance)
  //         }
  //     }

  //     window.addEventListener('resize', handleResize)
  //     calculateDistance() // Initial calculation on mount

  //     // Cleanup the event listener on component unmount
  //     return () => {
  //         window.removeEventListener('resize', handleResize)
  //     }
  // }, [])


  return (
    <>
      <div ref={currentRef}>
        {width < 480 ? (
          <Row className="video_box" ref={targetDivSmallScreenRef}>
            <VideoInformationSmall>
              <VideoNameLeft className="mb-5">
                <MediumTextWhite className="video_info_text ">
                  [{videoIndex}]
                </MediumTextWhite>
                <MediumTextWhite setwidth={'60%'} className="video_info_text ">
                  {currentLanguage === 'da'
                    ? currentVideo.field_video_title_da
                    : currentVideo.field_video_name}
                </MediumTextWhite>
              </VideoNameLeft>
              <SmallTextBox>
               
               <div
                 dangerouslySetInnerHTML={{
                   __html: currentLanguage === 'da'
                   ? currentVideo.field_description_rich_da
                   : currentVideo.field_description_rich,
                 }}
               />
               </SmallTextBox>
            </VideoInformationSmall>
          </Row>
        ) : (
          <></>
        )}

        <VideoSection className="g-0" ref={videoBoxRef}>
          <LazyVideoPlayer
            url={currentVideo?.field_video_link}
            videoWidth={videoWidth}
            videoHeight={videoHeight}
            controls={true}
            isPlay={isPlay}
            // setplay={() => setIsPlay(true)}
            playerRef={playerRef}
            handleDuration={handleDuration}
            style={{ cursor: 'pointer' }}
            handleVideoReady={handleVideoReady}
            handlePlay={handlePlay}
            isVideoLoaded={isVideoLoaded}
            windowwidth={width}
          />
        </VideoSection>

        <VideoInformation>
          <Row className="video_name_row">
            {width > 480 ? (
              <Col xs={6} sm={8} md={8} lg={6} xl={9} className="d-flex p-0">
                <MediumTextWhite className="project_index">
                  [{videoIndex}]
                </MediumTextWhite>
                <MediumTextWhite className="project_name">
                  {currentLanguage === 'da'
                    ? currentVideo.field_video_title_da
                    : currentVideo.field_video_name}
                </MediumTextWhite>
              </Col>
            ) : (
              <></>
            )}
            <Col
              ref={controlRef}
              xs={6}
              sm={4}
              md={4}
              lg={6}
              xl={3}
              className="d-flex p-0 custom-xxs-12"
            >
              <ControlBox
                className="w-100 d-flex "
                onMouseOver={handleMouseOver}
                onMouseLeave={handleMouseLeave}
              >
                {' '}
                {!isPlay ? (
                  <div
                    onClick={handlePlayClick}
                    className="w-100 d-flex controls_video_play align-items-center "
                  >
                    <PlayBox $currentcolor={currentColor} $transition={hover}>
                      <PlayIcon />
                    </PlayBox>{' '}
                    <PlayText $transition={hover}>
                      <MediumTextWhiteHover $transition={hover}>
                        {t('PLAY')} [{formatDuration(videoDuration)}]
                      </MediumTextWhiteHover>
                    </PlayText>
                  </div>
                ) : (
                  <></>
                )}
              </ControlBox>
            </Col>
          </Row>
          {width > 480 ? (
            <VideoText className="video_box_large">
              <>
                <SmallTextBox>
                <div
                  dangerouslySetInnerHTML={{
                    __html: currentLanguage === 'da'
                    ? currentVideo.field_description_rich_da
                    : currentVideo.field_description_rich,
                  }}
                />
                </SmallTextBox>
              </>
            </VideoText>
          ) : (
            <></>
          )}
        </VideoInformation>
      </div>
    </>
  )
}

export default VideoBox
