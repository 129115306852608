import React from 'react'
import styled, { keyframes } from 'styled-components';

const spin = keyframes`
  to {
    transform: rotate(1turn);
  }
`;

// Styled component
const CustomLoader = styled.div`
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background: conic-gradient(from 180deg at 50% 50%, rgba(255, 255, 255, 0) 0deg, #FFFFFF 360deg);
  -webkit-mask: radial-gradient(farthest-side, #0000 calc(100% - 8px), #000 0);
  animation: ${spin} 1s infinite linear;
`;
function Loading(props) {

   const {position}=props

  return (
    <CustomLoader style={position}/>
     
  )
}

export default Loading
