import axios from "axios";

const http = axios.create({
    baseURL: 'https://delta.thmple.com/api',
    // withCredentials: true
})

http.interceptors.request.use(request => {
    // const access_token = localStorage.getItem('access_token')

    // if (access_token) {
    //     request.headers.Authorization = `Bearer ${access_token}`
    // }
    

    return request
})

export default http