import { motion } from 'framer-motion'
import ImagesGrid from '../Components/ImagesGrid'
import i18n from '../i18n'
import { I18nextProvider } from 'react-i18next'
import { adminInfo, getPosts, getPostsById } from '../api/index'
import { keyframes, styled } from 'styled-components'
import { Container } from 'react-bootstrap'
import useDeltaStore from '../Store/Store'
import { useCallback, useEffect, useRef, useState } from 'react'
import { HeroComponent } from '../Components/HeroComponent'
import { HomeControl } from '../Components/HomeControl'
import Loading from '../Components/Loading'
import ProjectHeaderBox from '../Components/ProjectHeaderBox'
import VideoBox from '../Components/VideoBox'
import { useNavigate, useParams } from 'react-router-dom'
import DisableInteractionOverlay from '../Components/DisableInteractionOverlay'
import scrollTo from './extras'

const HeaderContainer = styled.div`
  padding: 0px 10px;
  overflow: hidden;
`
const VideoDiv = styled.div`
  // height: ${(props) => props.$height || '88vh'};
`

const moveVertically = (y, time) => keyframes`
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-${y}px);
  }
`

const AnimatedTab = styled.div`
  animation: ${(props) => moveVertically(props.y, props.time)}
    ${(props) => props.time}s both;
  width: 100%;
  overflow: hidden;
  // height: 1500px;
  background-color: #16161d !important;
`

const LoadingBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
`
//     //
function Project() {
  const params = useParams()

  const { id } = params

  const navigate = useNavigate()

  const width = useDeltaStore((state) => state.windowWidth)

  const [colorCount, setColorCount] = useState(0)

  const [currentColor, setCurrentColor] = useState('rgba(255, 71, 46, 1)')

  const [currentText, setCurrentText] = useState('R242 g6 b203')

  const [isAbout, setIsAbout] = useState(false)

  const [isPlay, setIsPlay] = useState(false)

  const [currentIndex, setCurrentIndex] = useState({ row: '', index: '' })

  const [videoWidth, setVideoWidth] = useState('')

  const [videoHeight, setVideoHeight] = useState('')

  const [indexHeight, setIndexHeight] = useState('0')

  const [scrollTime, setScrollTime] = useState(0)

  const [heroText, setHeroText] = useState(false)

  const [indexTab, setIndexTab] = useState(false)

  const [isVisible, setIsVisible] = useState(false)

  const [isVideoActive, setIsVideoActive] = useState(false)

  const [isActive, setIsActive] = useState(false)

  const [hover, setHover] = useState('false')

  const [backgroundimage, setBackgroundImage] = useState('')

  const [animation, setAnimation] = useState(false)

  const [moveToTop, setMoveToTop] = useState(false)

  const [video, setVideo] = useState([])

  const [currentVideo, setCurrentVideo] = useState([])

  const [currentVideoHome, setCurrentVideoHome] = useState('')

  const [currentVideoName, setCurrentVideoName] = useState('')

  const [videoDuration, setVideoDuration] = useState('')

  const [isVideoLoaded, setIsVideoLoaded] = useState(false)

  const [currentPage, setCurrentPage] = useState(1)

  const [key, setKeys] = useState(false)

  const [thumbnailKeys, setThumbnailKeys] = useState(false)

  const [imageBoxHeight, setImagesBoxHeight] = useState(0)

  const [selectedTab, setSelectedTab] = useState(false)

  const [videoIndex, setVideoIndex] = useState('')

  const [loading, setLoading] = useState(true)

  const [disableInteraction, setDisableInteraction] = useState(false)

  const [projectAnimation, setProjectAnimation] = useState(false)

  const [currentLanguage, setCurrentLanguage] = useState('')

  const [firstAnimation, setFirstAnimation] = useState(true)

  const [scroll, setScroll] = useState(true)

  const [heroKey, setHeroKey] = useState('')

  const [userData, setUserData] = useState({
    mail: '',
    address: '',
    lat: 55.48718590405666,
    lng: 10.216327843782562,
    instagramLink: '',
    contact: '',
    mailLink: '',
    adddressda: '',
    instagram: '',
    footerLeft: '',
    footerRight: '',
    services: [],
    clients: [],
    servicesDa: [],
    clientsDa: [],
    companyHeader:'',
    companyHeaderDa:''
  })

  const storeCurrentWidth = useDeltaStore((state) => state.storeCurrentWidth)

  const storeCurrentHeight = useDeltaStore((state) => state.storeCurrentHeight)

  const height = useDeltaStore((state) => state.windowHeight)

  const colour = [
    'rgba(255, 71, 46, 1)',
    'rgba(0, 81, 239, 1)',
    'rgba(221, 252, 34, 1)',
    'rgba(79, 237, 75, 1)',
  ]

  const text = ['R242 g6 b203', 'R0 G81 B239', 'R221 G252 B34', 'R79 G237 B75']

  const bgimages = ['one.png', 'two.png', 'three.png', 'four.png']

  const playerRef = useRef(null)

  const divRef = useRef(null)

  const headerRef = useRef(null)

  const videoBoxRef = useRef(null)

  const imagesBoxRef = useRef(null)

  const videoInfoLgRef = useRef(null)

  const currentRef = useRef(null)

  const controlRef = useRef(null)

  const myRef = useRef(null)

  const currentCount = parseInt(localStorage.getItem('colorCount')) || 0

  useEffect(() => {
    setVideoIndex(localStorage.getItem('videoIndex'))

    setColorCount(currentCount)
  }, [currentCount,id])

  const changeThemeColor = () => {
    if (currentCount < colour.length - 1) {
      const newCount = currentCount + 1

      localStorage.setItem('colorCount', newCount)

      setColorCount(newCount)
    } else {
      localStorage.setItem('colorCount', 0)

      setColorCount(0)
    }
    setIsVideoActive(false)
  }

  useEffect(() => {
    let setColor = colour[currentCount]

    let setText = text[currentCount]

    let setImage = bgimages[currentCount]

    setBackgroundImage(setImage)

    setCurrentText(setText)

    setCurrentColor(setColor)

    setHeroKey(Math.random())
  }, [colorCount])

  const setIndex = () => {
    return
  }

  const removeIndex = () => {
    return
  }

  const handleLanguageChange = (lang) => {
    setCurrentLanguage(lang)

    i18n.changeLanguage(lang)

    setFirstAnimation(true)

    setHeroKey(Math.random())
  }

  const handleResize = () => {
    const width = window.innerWidth

    const height = window.innerHeight

    storeCurrentWidth(width)

    storeCurrentHeight(height)
  }

  useEffect(() => {
    if (typeof window !== 'undefined') {
      storeCurrentWidth(window.innerWidth)

      storeCurrentHeight(window.innerHeight)

      window.addEventListener('resize', handleResize)

      return () => {
        window.removeEventListener('resize', handleResize)
      }
    }
  }, [])

  useEffect(() => {
    if (width < 768) {
      setVideoWidth(width - 20)

      setVideoHeight((width * 9) / 16)
    }
    if (width >= 768 && width < 1200) {
      let calcWidth = width - (width - 750)

      let calcHeight = (calcWidth * 9) / 16

      setVideoWidth(calcWidth)

      setVideoHeight(calcHeight)
    }
    if (width >= 1200 && width < 1540) {
      let calcWidth = width - (width - 950)

      let calcHeight = (calcWidth * 9) / 16

      setVideoWidth(calcWidth)

      setVideoHeight(calcHeight)
    } else if (width > 1540) {
      setVideoWidth(1196)

      setVideoHeight(673)
    }
  }, [width])

  useEffect(() => {
    if (divRef.current) {
      const currentPosition = divRef?.current?.offsetTop

      setIndexHeight(currentPosition)
    }
  }, [indexHeight, moveToTop])

  useEffect(() => {
    const fetchData = async (id) => {
      try {
        if (id) {
          const response = await getPostsById(id)

          const adminResponse = await adminInfo()

          const allVideosResponse = await getPosts()

          if (response) {
            setLoading(false)
          }

          const data = response.data.data

          const adminData = adminResponse.data.data

          const allVideosData = allVideosResponse.data.data

          if (!response) {
            setIsVideoLoaded(false)
          }

          setUserData({
            ...userData,
            mail: adminData.field_email,
            mailLink: adminData.field_mail_link,
            instagramLink: adminData.field_instagram_link,
            contact: adminData.field_contact,
            linkedinLink: adminData.field_linked_in_link,
            lat: adminData.field_lat,
            lng: adminData.field_lng,
            address: adminData.field_address,
            addressda: '',
            instagram: adminData.field_instagram,
            footerLeft: adminData.field_left_control,
            footerRight: adminData.field_right_control,
            companyHeader: adminData.field_company_header,
            companyHeaderDa: adminData.field_company_header_da,
            services: adminData.field_services_list,
            clients: adminData.field_clients,
            servicesDa: adminData.field_client_da,
            clientsDa: adminData.field_services_da,
          })

          setCurrentVideo(data)

          setVideo(allVideosData)
        }
      } catch (err) {
        console.log(err)
      }
    }
    fetchData(id)
  }, [id])

  const handleDuration = (seconds) => {
    setVideoDuration(seconds)
  }

  const formatDuration = (seconds) => {
    const minutes = Math.floor(seconds / 60)

    const remainingSeconds = seconds % 60

    const formattedMinutes = String(minutes).padStart(2, '0')

    const formattedSeconds = String(remainingSeconds).padStart(2, '0')

    return `${formattedMinutes}:${formattedSeconds}`
  }

  const handleVideoReady = () => {
    setIsVideoLoaded(true)
  }

  const handlePlay = () => {
    if (!isVideoLoaded) {
      playerRef.current.getInternalPlayer().pause()
    }
  }

  const handlePageClick = (page) => {
    setCurrentPage(page)

    if (page !== currentPage) {
      setThumbnailKeys(Math.random())

      setSelectedTab(true)
    }
  }

  useEffect(() => {
    const ref1 = videoBoxRef?.current?.clientHeight

    const ref2 = headerRef?.current?.clientHeight

    const ref3 = imagesBoxRef?.current?.clientHeight || 100

    const ref4 = videoInfoLgRef?.current?.clientHeight || 100

    const ref5 = controlRef?.current?.clientHeight

    const calculatedHight = height - ref1 - ref2 - ref3 - ref4 - ref5 - 100

    const headerAndVideoHeight =
      myRef.current?.clientHeight + currentRef.current?.clientHeight

    setImagesBoxHeight(height - headerAndVideoHeight - 80)
  }, [loading,videoIndex,id])

  const minHeight = width > 767 ? 'auto' : `${imageBoxHeight}px`

  const handleScrollAnimation = () => {

    if (scroll) {
      const scrollPosition = window.scrollY

      // if (parallaxVideoRef.current && parallaxContentRef.current) {
      //   parallaxVideoRef.current.style.transform = `translateY(${
      //     scrollPosition * 0.5
      //   }px)`

      //   parallaxContentRef.current.style.transform = `translate(-50%, ${
      //     scrollPosition * 0.3
      //   }px)`
      // }
    }
  }

  const targetDivRef = useRef(null)

  const targetDivSmallScreenRef = useRef(null)

  const parallaxVideoRef = useRef(null)

  const parallaxContentRef = useRef(null)

  const handleAnimated = () => {
    scrollTo(0, 2933)

    setMoveToTop(true)

    setDisableInteraction(true)
  }
  const [scrollY, setScrollY] = useState(0);

  // useEffect(() => {
  //   const handleScroll = () => {
  //     setScrollY(window.scrollY);
  //     const scrollPosition = window.scrollY

  //     if (parallaxVideoRef.current && parallaxContentRef.current) {
  //       parallaxVideoRef.current.style.transform = `translateY(${
  //         scrollPosition * 0.5
  //       }px)`

  //       parallaxContentRef.current.style.transform = `translate(-50%, ${
  //         scrollPosition * 0.3
  //       }px)`
  //     }
  //   };

  //   window.addEventListener('scroll', handleScroll);
  //   return () => {
  //     window.removeEventListener('scroll', handleScroll);
  //   };
  // }, []);

  const handleRoute=(videoId, id, index)=>{
    navigate(`/article${videoId}`)

    localStorage.setItem('videoId', id)

    localStorage.setItem('videoIndex', index)

    window.scrollTo(0, 0);

  }
  return (
    <>
      {loading ? (
        <LoadingBox>
          <Loading />
        </LoadingBox>
      ) : (
        <I18nextProvider i18n={i18n}>
          <Container
            className={`p-0 g-0 home_section_container video_conatiner `}
            fluid
          >
            {/* <DisableInteractionOverlay isScrollDisabled={disableInteraction} /> */}
            <div className="parallax-bg">
              <>
                {/* .......................................VideoHeader and description......................... */}
                {/* <motion.div> */}

                <VideoDiv
                  // style={{ y: y1 }}
                  // $height={`${newPosition}px`}
                  ref={parallaxVideoRef}
                >
                  <HeaderContainer ref={myRef}>
                    <ProjectHeaderBox
                      width={width}
                      isAbout={isAbout}
                      userData={userData}
                      currentVideo={currentVideo}
                      handleCloseClick={() => setIsAbout(false)}
                      handleAboutClick={() => setIsAbout(true)}
                    />
                  </HeaderContainer>

                  <VideoBox
                    width={width}
                    currentRef={currentRef}
                    videoInfoLgRef={videoInfoLgRef}
                    videoIndex={videoIndex}
                    currentVideo={currentVideo}
                    videoBoxRef={videoBoxRef}
                    videoWidth={videoWidth}
                    videoHeight={videoHeight}
                    isPlay={isPlay}
                    playerRef={playerRef}
                    handleDuration={handleDuration}
                    handlePlay={handlePlay}
                    isVideoLoaded={isVideoLoaded}
                    handleVideoReady={handleVideoReady}
                    controlRef={controlRef}
                    currentColor={currentColor}
                    hover={hover}
                    formatDuration={formatDuration}
                    videoDuration={videoDuration}
                    handleMouseOver={() => setHover('true')}
                    handleMouseLeave={() => setHover('false')}
                    handlePlayClick={() => setIsPlay((prev) => !prev)}
                    targetDivRef={targetDivRef}
                    targetDivSmallScreenRef={targetDivSmallScreenRef}
                  />
                </VideoDiv>
                {/* .......................................VideoHeader and description......................... */}
              </>
              {/* .......................................Images Box......................... */}

              {/* .......................................Images Box......................... */}

              <div
                ref={parallaxContentRef}
                // className="parallax-content"
                // style={{ transform: `translate(-50%, ${scrollPosition * 0.3}px)` }}
              ></div>

              {/* .......................................Bottom Div Box......................... */}

              <div
                // style={{ background: 'black', height: '1000px' }}

                // transition={{ duration: 10, ease: 'easeInOut' }}
              >
                <div style={{ minHeight }} ref={imagesBoxRef}>
                  {currentVideo?.field_video_thumbnails?.length > 0 ? (
                    <motion.div>
                      <div
                        className={`${
                          !animation ? 'animated_list' : 'hide'
                        } image_box`}
                        id="constrained-container"
                      >
                        <ImagesGrid
                          images={currentVideo?.field_video_thumbnails}
                        />
                      </div>
                    </motion.div>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
              </div>

              <AnimatedTab
                // onClick={handleAnimated}
                // y={moveToTop ? indexHeight : ''}
                // time={moveToTop ? scrollTime : ''}
                // ref={divRef}
                // className="parallax-content"
              >
                {/* <Container className="p-0 g-0 video_section_home_container" fluid> */}
                <HeroComponent
                  activeColor={currentColor}
                  openText={() => setHeroText(true)}
                  heroText={heroText}
                  hideHeroText={() => setHeroText(false)}
                  isIndexTab={indexTab}
                  isVisible={isVisible}
                  isVideoActive={isVideoActive}
                  position={'relative'}
                  tab={'videoplay'}
                  setactive={() => setIsActive(true)}
                  isActive={isActive}
                  removeActive={() => setIsActive(false)}
                  width={width}
                  keys={heroKey}
                  currentVideoHome={currentVideoHome}
                  currentText={currentText}
                  currentVideoName={currentVideoName}
                  changeThemeColor={changeThemeColor}
                  heroInfoText={userData}
                  triggerScrollAnimation={handleScrollAnimation}
                  projectAnimation={projectAnimation}
                  firstAnimation={firstAnimation}
                />

                <HomeControl
                  changeThemeColor={changeThemeColor}
                  activeColor={currentColor}
                  currentText={currentText}
                  currentIndex={currentIndex}
                  setIndex={setIndex}
                  removeIndex={removeIndex}
                  currentbackgroundimage={backgroundimage}
                  handleLanguageChange={handleLanguageChange}
                  video={video}
                  width={width}
                  setThumbnailKeys={thumbnailKeys}
                  handlePageClick={handlePageClick}
                  currentPage={currentPage}
                  key={key}
                  selectedTab={selectedTab}
                  firstAnimation={firstAnimation}
                  heroInfoText={userData}
                  handleRoute={handleRoute}
                />
                {/* </Container> */}
              </AnimatedTab>
            {/* .......................................Bottom Div Box......................... */}
          </Container>
        </I18nextProvider>
      )}
    </>
  )
}
export default Project
