import React from 'react'
import { Col, Row } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { Link, useNavigate } from 'react-router-dom'

import styled from 'styled-components'
const HeaderText = styled.div`
    display: flex;
    justify-content: space-between;
    padding: 10px 10px;
`
const LogoBox = styled.div`
    cursor: url('/Δ (1).png'), auto;
`

const RightHeaderBox = styled.div`
    width: 25%;
    display: flex;
    justify-content: space-between;
    padding-right: 15px;
`

const ListBox = styled.ul`
    list-style: none;
`
const ListWrapper = styled.div`
    position: absolute;
`
const ToggleList = styled.div`
    position: relative;
    width: 50%;
`
const OptionBox = styled.div`
    cursor: url('/Δ (1).png'), auto;
`

const SmallTextWhite = styled.p`
    font-size: 10px;
    line-height: 12px;
    margin-bottom: 0px !important;
    color: white;
    font-weight: 500;
    height: 60%;
`
const ProjectHeaderBox = (props) => {
    const { width, isAbout, userData, currentVideo, handleCloseClick, handleAboutClick } = props

    const { t } = useTranslation()

    const navigate = useNavigate()
    
    const currentLanguage = localStorage.getItem('i18nextLng')

    const clientsData=currentLanguage ==='da'? userData.clientsDa : userData.clients

    const servicesData=currentLanguage ==='da'? userData.servicesDa : userData.services

    return (
        <>
            <Row className="project_header">
                {' '}
                    <Col xs={6} sm={8} md={8} lg={6} xl={9} className="p-0" >
                        <SmallTextWhite className='pe-2' onClick={()=>navigate('/')}>Delta Productions</SmallTextWhite>
                    </Col>
                <Col xs={6} sm={4} md={4} lg={6} xl={3} className="p-0">
                    <Row>
                        <Col className="animated_list p-0" sm={10} lg={10}>
                            {!isAbout && width > 1440 ? (
                                <SmallTextWhite className="animated_list ">
                                    {' '}
                                    {currentLanguage ==='da'?currentVideo.field_video_title_da:currentVideo.field_video_name}
                                </SmallTextWhite>
                            ) : width > 1440 ? (
                                <>
                                    <ListWrapper className="animated_list">
                                        <ListBox>
                                            {clientsData?.map((curr, index) => {
                                                return (
                                                    <li key={index} className="list_insider">
                                                        <SmallTextWhite className="pointer-special">
                                                            {curr?.value}
                                                        </SmallTextWhite>
                                                    </li>
                                                )
                                            })}
                                        </ListBox>

                                        <ListBox>
                                            {servicesData?.map((curr, index) => {
                                                return (
                                                    <li key={index} className="list_insider">
                                                        <SmallTextWhite className="pointer-special">
                                                            {curr?.value}
                                                        </SmallTextWhite>
                                                    </li>
                                                )
                                            })}
                                        </ListBox>

                                        <ListBox>
                                            <SmallTextWhite>{t('CONTACT')}</SmallTextWhite>
                                            <li className="list_insider">
                                                <SmallTextWhite className="pointer-special">
                                                    {userData?.mail}
                                                </SmallTextWhite>
                                            </li>

                                            <li className="list_insider">
                                                <SmallTextWhite
                                                    className="pointer-special"
                                                    onClick={() => window.open(`${userData?.mailLink}`)}
                                                >
                                                    {userData?.contact}
                                                </SmallTextWhite>
                                            </li>
                                        </ListBox>

                                        <ListBox>
                                            <SmallTextWhite>{t('FOLLOW')}</SmallTextWhite>

                                            <li
                                                className="list_insider"
                                                onClick={() => window.open(`${userData?.instagramLink}`)}
                                            >
                                                <SmallTextWhite className="pointer-special">INSTAGRAM</SmallTextWhite>
                                            </li>

                                            <li
                                                className="list_insider"
                                                onClick={() => window.open(`${userData?.linkedinLink}`)}
                                            >
                                                <SmallTextWhite className="pointer-special">LINKEDIN</SmallTextWhite>
                                            </li>
                                        </ListBox>
                                    </ListWrapper>
                                </>
                            ) : (
                                <></>
                            )}
                        </Col>
                        {width > 1440 ? (
                            <Col sm={2} lg={2} className="animated_list">
                                {!isAbout ? (
                                    <SmallTextWhite onClick={handleAboutClick} className="animated_list">
                                        [{t('About')}]
                                    </SmallTextWhite>
                                ) : (
                                    <SmallTextWhite onClick={handleCloseClick} className="animated_list cross_icon">
                                        [x]
                                    </SmallTextWhite>
                                )}
                            </Col>
                        ) : (
                            <></>
                        )}
                    </Row>
                </Col>
            </Row>
        </>
    )
}

export default ProjectHeaderBox
