import React from 'react'
import { styled } from 'styled-components'
import { motion } from 'framer-motion'

const SmallTextWhite = styled.p`
    font-size: 10px;
    line-height: 12px;
    margin-bottom: 0px !important;
    color: white;
    font-weight: 500;
`

const ThumbnailBox = styled.div`
    background-image: url(${(props) => `${props.$img}` || '/image5.png'});
    width: 170px;
    height: 90px;

    @media (min-width: 0px) and (max-width: 425px) {
        width: 94%;
        height: 95px;
    }
    @media (min-width: 426px) and (max-width: 671px) {
        width: 175px;
        height: 95px;
    }
    @media (min-width: 672px) and (max-width: 767px) {
        width: 208px;
        height: 116px;
    }
    @media (min-width: 768px) and (max-width: 1031px) {
        width: 139px;
        height: 78px;
    }
    @media (min-width: 1031px) {
        width: 139px;
        height: 78px;
    }

    cursor: url('/Δ (1).png'), auto;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
`
const ResponsiveTextBox = styled.div`
    width: 140px;
    @media (min-width: 0px) and (max-width: 371px) {
        width: 120px;
    }
    // overflow: hidden;
    // text-overflow: ellipsis;
    // white-space: nowrap;
`
const containerVariants = {
    hidden: {
        opacity: 0,
        transition: {
            type: 'spring',
            bounce: 0,
            delay: 0.1,
            delayChildren: 0.2,
        },
    },
    visible: {
        opacity: 1,
        transition: {
            type: 'spring',
            bounce: 0,
            duration: 3,
            mass: 1,
            delay: 0.2,
            delayChildren: 0.2,
            staggerChildren: 0.7,
        },
    },
}

const childVariants = {
    hidden: {
        opacity: 0,
        y: 30,
    },
    visible: {
        opacity: 1,
        y: 0,
        transition: {
            type: 'tween',
            duration: 1.8,
            ease: 'easeInOut',
            stiffness: 0,
            damping: 12,
        },
    },
}

export const ThumbailImages = (props) => {
    const {
        handleRoute,
        currentIndex,
        setIndex,
        removeIndex,
        currentbackgroundimage,
        result,
        width,
        startindex,
        state,
        setKeys,
        selectedTab,
        rowLength,
    } = props
    
    const currentLanguage = localStorage.getItem('i18nextLng')

    return (
        <>
            {width > 767 ? (
                <motion.div className="row animate thumbnail_image" key={setKeys}>
                    {result?.map((row, index) => {
                        return (
                            <div
                                onClick={() => handleRoute(row.real_path, row.id, startindex + (index + 1))}
                                key={index}
                                className={`col-lg-3 col-6 col-md-4 thumnail_images_margin link_decoration `}
                            >
                                <div className="py-1">
                                    <SmallTextWhite
                                        className={`${
                                            !selectedTab
                                                ? index > rowLength
                                                    ? 'animate glow delay-3'
                                                    : 'animate glow delay-1'
                                                : index > rowLength
                                                ? 'animate glow delay-30'
                                                : 'animate glow delay-10'
                                        } py-1`}
                                    >
                                         [{startindex + (index + 1)}] {currentLanguage ==='da'?row.field_video_title_da:row.name}
                                    </SmallTextWhite>
                                </div>

                                <ThumbnailBox
                                    $img={
                                        index === currentIndex ? `/${currentbackgroundimage}` : row.field_thumbnail_medium_url
                                    }
                                    onMouseEnter={() => setIndex(index, row.field_preview_url, row.name,row.field_video_title_da)}
                                    onMouseLeave={() => removeIndex(index)}
                                    className={`${
                                        !selectedTab
                                            ? index > rowLength
                                                ? 'animate glow delay-4'
                                                : 'animate glow delay-2'
                                            : index > rowLength
                                            ? 'animate glow delay-40'
                                            : 'animate glow delay-20'
                                    } px-2`}
                                ></ThumbnailBox>
                            </div>
                        )
                    })}
                </motion.div>
            ) : (
                <motion.div className="custom_row animate" key={setKeys}>
                    {result.map((row, index) => {
                        return (
                            <div
                                onClick={() => handleRoute(row.real_path, row.id, startindex + (index + 1))}
                                key={index}
                                className={`col-lg-3 col-6 col-md-4 thumnail_images_margin link_decoration `}
                            >
                                {width >= 672 ? (
                                    <>
                                        <div className="py-1">
                                            <SmallTextWhite
                                                className={`${
                                                    !selectedTab
                                                        ? index < 2
                                                            ? 'animate glow delay-w1'
                                                            : index < 4
                                                            ? 'animate glow delay-w3'
                                                            : index < 6
                                                            ? 'animate glow delay-w5'
                                                            : 'animate glow delay-w7'
                                                        : index < 2
                                                        ? 'animate glow delay-w10'
                                                        : index < 4
                                                        ? 'animate glow delay-w30'
                                                        : index < 6
                                                        ? 'animate glow delay-w50'
                                                        : 'animate glow delay-w70'
                                                } py-1`}
                                            >
                                               [{startindex + (index + 1)}]  {currentLanguage ==='da'?row.field_video_title_da:row.name}
                                            </SmallTextWhite>
                                        </div>
                                        <ThumbnailBox
                                            $img={
                                                index === currentIndex
                                                    ? `/${currentbackgroundimage}`
                                                    : row.field_thumbnail_medium_url
                                            }
                                            onMouseEnter={() => setIndex(index, row.field_preview_url)}
                                            onMouseLeave={() => removeIndex(index)}
                                            className={`${
                                                !selectedTab
                                                    ? index < 2
                                                        ? 'animate glow delay-w2'
                                                        : index < 4
                                                        ? 'animate glow delay-w4'
                                                        : index < 6
                                                        ? 'animate glow delay-w6'
                                                        : 'animate glow delay-w8'
                                                    : index < 2
                                                    ? 'animate glow delay-w20'
                                                    : index < 4
                                                    ? 'animate glow delay-w40'
                                                    : index < 6
                                                    ? 'animate glow delay-w60'
                                                    : 'animate glow delay-w80'
                                            } py-1 px-2`}
                                        ></ThumbnailBox>
                                    </>
                                ) : (
                                    <></>
                                )}

                                {width < 671 ? (
                                    <>
                                        <ThumbnailBox
                                            $img={
                                                index === currentIndex
                                                    ? `/${currentbackgroundimage}`
                                                    : row.field_thumbnail_medium_url
                                            }
                                            onMouseEnter={() => setIndex(index, row.field_preview_url)}
                                            onMouseLeave={() => removeIndex(index)}
                                            className={`${
                                                !selectedTab
                                                    ? index < 2
                                                        ? 'animate glow delay-w1'
                                                        : index < 4
                                                        ? 'animate glow delay-w3'
                                                        : index < 6
                                                        ? 'animate glow delay-w5'
                                                        : 'animate glow delay-w7'
                                                    : index < 2
                                                    ? 'animate glow delay-w10'
                                                    : index < 4
                                                    ? 'animate glow delay-w30'
                                                    : index < 6
                                                    ? 'animate glow delay-w50'
                                                    : 'animate glow delay-w70'
                                            } py-1`}
                                        ></ThumbnailBox>
                                        <ResponsiveTextBox className="py-2">
                                            <SmallTextWhite
                                                className={`${
                                                    !selectedTab
                                                        ? index < 2
                                                            ? 'animate glow delay-w2'
                                                            : index < 4
                                                            ? 'animate glow delay-w4'
                                                            : index < 6
                                                            ? 'animate glow delay-w6'
                                                            : 'animate glow delay-w8'
                                                        : index < 2
                                                        ? 'animate glow delay-w20'
                                                        : index < 4
                                                        ? 'animate glow delay-w40'
                                                        : index < 6
                                                        ? 'animate glow delay-w60'
                                                        : 'animate glow delay-w80'
                                                } py-1 `}
                                            >
                                                [{startindex + (index + 1)}]  {currentLanguage ==='da'?row.field_video_title_da:row.name}
                                            </SmallTextWhite>
                                        </ResponsiveTextBox>
                                    </>
                                ) : (
                                    <></>
                                )}
                            </div>
                        )
                    })}
                </motion.div>
            )}
        </>
    )
}
