import React from 'react'

export const PlayIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="15"
      height="12"
      fill="none"
      viewBox="0 0 15 12"
      className='play_svg'
    >
      <path
        fill="#16161D"
        d="M6.36.8l4.28 4.48H.8v2.24h9.84L6.36 12h2.96l4.84-5.6L9.32.8H6.36z"
      ></path>
    </svg>
  )
}