import React, { useEffect, useRef, useState } from 'react'
import { styled } from 'styled-components'
import List from './List'
import List2 from './List2'
// import { redirect } from 'next/navigation'
import { useTranslation } from 'react-i18next'
import { useInView } from 'framer-motion'

// import '../pages/globals.css'

// const ExtraBold = localFont({
//   src: '../public/fonts/TWKEverett-Extrabold-web.ttf',
// })

const AddressRow = styled.div`
  padding: 0px 12px;
`
const SmallTextWhite = styled.p`
  font-size: 10px;
  line-height: 12px;
  margin-bottom: 0px !important;
  font-weight: 500;
  height: 60%;
`
const InfoRow = styled.div`
  display: flex;
  width: 17%;
  justify-content: space-between;
  @media (min-width: 0px) and (max-width: 325px) {
    width: 100%;
  }
  @media (min-width: 326px) {
    width: 390px;
  }
`
const HeaderRow = styled.div`
  margin-bottom: 145px;
  padding: 0px 12px;
  @media (max-width: 325px) {
    margin-bottom: 40px;
  }
  @media (min-width: 326px) and (max-width: 480px) {
    margin-bottom: 40px;
  }

  @media (min-width: 481px) and (max-width: 671px) {
    margin-bottom: 7%;
  }
  @media (min-width: 672px) and (max-width: 767px) {
    margin-bottom: ${(props) => (props.margin ? '30px' : '7%')};
  }
  @media (min-width: 768px) and (max-width: 1031px) {
    margin-bottom: ${(props) => (props.margin ? props.margin : '7%')};
  }
  @media (min-width: 1032px) and (max-width: 1439px) {
    margin-bottom: ${(props) => (props.margin ? props.margin : '7%')};
  }

  @media (min-width: 1440px) and (max-width: 1824px) {
    margin-bottom: ${(props) => (props.margin ? props.margin : '7%')};
  }
  @media (min-width: 1825px) {
    margin-bottom: ${(props) => (props.margin ? props.margin : '100px')};
  }
`

const HeroSection = styled.section`
  background: ${(props) => props.$currentcolor || 'rgba(255, 71, 46, 1)'};
  position: relative;

  @media (min-width: 0px) and (max-width: 480px) {
    height: ${(props) => (props.smheight ? props.smheight+'px' : '230px')};
  }

  @media (min-width: 481px) and (max-width: 671px) {
    height: ${(props) => (props.smheight ? props.smheight+'px' : '230px')};

  }
  @media (min-width: 672px) and (max-width: 767px) {
    height: ${(props) => (props.height === undefined ? '280px' : props.height + 'px')};

  }
  @media (min-width: 768px) and (max-width: 1031px) {
    height: ${(props) => (props.height === undefined ? '394px' : props.height + 'px')};
  }
  @media (min-width: 1032px) and (max-width: 1439px) {
    height: ${(props) => (props.height === undefined ? '457.594px' : props.height + 'px')};
  }
  @media (min-width: 1440px) and (max-width: 1824px) {
    height: ${(props) => (props.height === undefined ? '480.594px' : props.height + 'px')};
  }
  @media (min-width: 1825px) {
    height: ${(props) => (props.height === undefined ? '542.88px' : props.height + 'px')};
  }
`
const LogoContainer = styled.div`
  // margin-left: -18px;
  // font-family: 'TWK Everett';
`

const FounderDetails = styled.div`
  margin-bottom: 1rem;
`
const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
`
const LogoText = styled.p`
  font-size: 400px;
  font-weight: 750;
  line-height: 320px;
  letter-spacing: -0.05em;

  @media (min-width: 0px) and (max-width: 325px) {
    font-size: 4rem;
    line-height: 4.4rem;
    margin-left: -2px;
  }
  @media (min-width: 326px) and (max-width: 480px) {
    font-size: 6rem;
    line-height: 5.4rem;
    margin-left: -7px;
  }
  @media (min-width: 481px) and (max-width: 671px) {
    font-size: 8.1rem;
    line-height: 7rem;
    margin-left: -9px;
  }
  @media (min-width: 672px) and (max-width: 767px) {
    font-size: 9.125rem;
    line-height: 8rem;
    margin-left: -10px;
  }
  @media (min-width: 768px) and (max-width: 1031px) {
    font-size: 10.375rem;
    line-height: 8.8rem;
    margin-left: -12px;
  }
  @media (min-width: 1032px) and (max-width: 1439px) {
    font-size: 17rem;
    line-height: 13.75rem;
    margin-left: -18px;
  }
  @media (min-width: 1440px) and (max-width: 1824px) {
    font-size: 18.43rem;
    line-height: 15rem;
    margin-left: -19px;
  }
  @media (min-width: 1825px) {
    font-size: 23rem;
    line-height: 18rem;
    margin-left: -25px;
  }
`
const SmallText = styled.p`
  font-size: 10px;
  line-height: 12px;
  margin-bottom: 0px !important;
  font-weight: 500;
`
const SmallTextAbout = styled.div`
  font-size: 10px;
  line-height: 12px;
  margin-bottom: 0px !important;
  font-weight: 500;
  opacity: 1;
  padding-top: 4px;
  transition: opacity 0.4s ease;
  &:hover {
    color: white;
    opacity: 0.8;
  }
`

const BackgroundHeader = styled.div`
  position: ${(props) => props.$position || ''};
  top: 0;
  color: ${(props) => props.color || 'rgba(22, 22, 29, 1)'};
  width: 100%;
`

const RightTextBox = styled.div`
  display: flex;
  width: 30%;
  justify-content: ${(props) => props.content || 'flex-end'};
  padding-top: 5px;
`
const VerticalText = styled.p`
  text-transform: uppercase;
  color: #16161d;
  transform: rotate(90deg);
  font-size: 10px;
  font-weight: 500;
  margin-top: 42px;
  position: absolute;
  right: -39px;
  top: 4px;
  width: 106px;
  height: 20px;
`
const AnimatedRightBox = styled.div`
  display: flex;
  width: 30%;
  justify-content: ${(props) => props.content || 'flex-end'};
`
const VideoBackground = styled.div`
  position: relative;
  overflow: hidden;
  width: 100vw;
  height: 542.88px;

  @media (min-width: 0px) and (max-width: 671px) {
    height: ${(props) => (props.height ? props.height : '230px')};
  }
  @media (min-width: 672px) and (max-width: 767px) {
    height: ${(props) => (props.height ? props.height : '305px')};
  }
  @media (min-width: 768px) and (max-width: 1031px) {
    height: ${(props) => (props.height ? props.height : '384px')};
  }
  @media (min-width: 1032px) and (max-width: 1439px) {
    height: ${(props) => (props.height ? props.height : '437.594px')};
  }
  @media (min-width: 1440px) and (max-width: 1824px) {
    height: ${(props) => (props.height ? props.height : '480.594px')};
  }
  @media (min-width: 1825px) {
    height: ${(props) => (props.height ? props.height : '542.88px')};
  }
`
export const HeroComponent = (props) => {
  const {
    activeColor,
    isVideoActive,
    height,
    setactive,
    isActive,
    removeActive,
    position,
    heroText,
    hideHeroText,
    openText,
    currentText,
    changeThemeColor,
    isIndexTab,
    isVisible,
    tab,
    keys,
    handleHeaderClick,
    width,
    currentVideoHome,
    currentVideoName,
    firstAnimation,
    headerText,
    heroInfoText,
    triggerScrollAnimation,
    projectAnimation,
    deltaRefLogo,
    logoInfoDistance,
  } = props

  const headerRef = useRef(null)
  // const ref = useRef(null)
  const isInView = useInView(headerRef)

  const [scrollCount, setScrollCount] = useState(0)


  // useEffect(() => {
  //     if(isInView){
  //     triggerScrollAnimation()
  //     }
  // }, [isInView])

  const [state,setState]=useState(true)

  const isInViewport = (element) => {
    if (!element) return false

    const bounding = element?.getBoundingClientRect()

    return (
      bounding.top >= 0 &&
      bounding.left >= 0 &&
      bounding.right <= window.innerWidth &&
      bounding.bottom <= window.innerHeight
    )
  }

  const handleScroll = () => {
    if(!state){
     return 
    }
    if (isInViewport(headerRef.current)) {
      setState(false)
      triggerScrollAnimation()
    }
  }


  useEffect(() => {
    window.addEventListener('scroll', handleScroll)

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  const about = () => {
    if ((headerText && isActive) || isActive) {
      return <List heroInfoText={heroInfoText} />
    } else if (headerText) {
      return <SmallText className="py-1">[{currentVideoName}]</SmallText>
    } else {
      return <></>
    }
  }
  const about1 = () => {
    if ((headerText && isActive) || isActive) {
      return <List heroInfoText={heroInfoText} />
    } else if (headerText) {
      return <SmallText className="py-1">[{currentVideoName}]</SmallText>
    } else {
      return <></>
    }
  }

  const aboutTogggle1 = () => {
    if (!isActive && isVisible) {
      return (
        <>
          <div onClick={setactive} className="cursor-active">
            <SmallTextAbout className="animated_list py-1">
              [{t('About')}]
            </SmallTextAbout>
          </div>
        </>
      )
    } else if (isActive) {
      return (
        <>
          <div
            onClick={removeActive}
            className="cursor-active px-2 animated_list"
          >
            [x]
          </div>
        </>
      )
    }
  }
  const aboutTogggle = () => {
    if (!isActive) {
      return (
        <>
          <div onClick={setactive} className="cursor-active">
            <SmallTextAbout className="animated_list">
              [{t('About')}]
            </SmallTextAbout>
          </div>
        </>
      )
    } else {
      return (
        <>
          <div
            onClick={removeActive}
            className="cursor-active px-2 animated_list cross_icon"
          >
            [x]
          </div>
        </>
      )
    }
  }

  const { t, i18n } = useTranslation()

  const currentLanguage = i18n.language
  
 
  // const handleScroll = () => {
  //   setScrollCount((prevScrollCount) => prevScrollCount + 1)
  // }

  // useEffect(() => {
  //   document.addEventListener('scroll', handleScroll)

  //   return () => {
  //     document.removeEventListener('scroll', handleScroll)
  //   }
  // }, [])


  return (
    <>
      <HeroSection
        className="g-0 animated_hero"
        $currentcolor={activeColor}
        height={height}
        smheight={`${heroText ? '645' : ''}`}
        key={keys}
        onClick={handleHeaderClick}
        style={{ background: `${activeColor}:'rgba(255, 71, 46, 1)'` }}
      >
        {headerText && (
          <VideoBackground className="video-background" height={`${height}px`}>
            <iframe
              src={`${currentVideoHome}?autoplay=1&muted=1`}
              // allow="autoplay; fullscreen; picture-in-picture"
              allowFullScreen
              loading="lazy"
              preload="auto"
              className="lozad"
              data-src="embed.html"
            ></iframe>
          </VideoBackground>
        )}
        <BackgroundHeader
          color={headerText ? 'white' : 'rgba(22, 22, 29, 1)'}
          $position={position}
        >
          <HeaderRow margin={`${logoInfoDistance}px`}>
            <HeaderContainer>
              <LogoContainer ref={deltaRefLogo}>
                <LogoText className="logo_text" >DELTA</LogoText>
              </LogoContainer>
              {tab === 'home' ? (
                <RightTextBox
                  className=""
                  content={
                    headerText || isActive ? 'space-between' : 'flex-end'
                  }
                >
                  {width < 672 ? (
                    <>
                      <VerticalText onClick={changeThemeColor}>
                        [{currentText}]
                      </VerticalText>
                    </>
                  ) : (
                    <>
                      {about()}
                      <div>{aboutTogggle()}</div>
                    </>
                  )}
                </RightTextBox>
              ) : tab === 'videoplay' && isIndexTab ? (
                <AnimatedRightBox
                  className={`px-4 ${isVisible ? 'tracking-in-expand' : ''}`}
                  content={
                    headerText || isActive ? 'space-between' : 'flex-end'
                  }
                >
                  {isIndexTab && width < 672 ? (
                    <>
                      <VerticalText onClick={changeThemeColor}>
                        [{currentText}]
                      </VerticalText>
                    </>
                  ) : (
                    <>
                      {about1()}
                      <div>{aboutTogggle1()}</div>
                    </>
                  )}
                </AnimatedRightBox>
              ) : (
                <></>
              )}
            </HeaderContainer>
          </HeaderRow>

          <div ref={headerRef}></div>

          <AddressRow
            className={`${
              firstAnimation || projectAnimation
                ? 'animate animated_entrance delay-01'
                : ''
            }`}
          >
            <div>
              <FounderDetails>
                <SmallText className="user_details_text">
                  {currentLanguage === 'da'? heroInfoText?.companyHeaderDa:heroInfoText?.companyHeader}
                </SmallText>
              </FounderDetails>

              <InfoRow>
                {width > 672 || heroText ? (
                  <>
                    <div>
                      {heroText ? (
                        <List2
                          hideHeroText={hideHeroText}
                          heroInfoText={heroInfoText}
                          width={width}
                        />
                      ) : (
                        <>
                          {' '}
                          <SmallText>{t('MAIL')}</SmallText>
                          <SmallText>{t('ADDRESS')} </SmallText>
                          <SmallText>{t('INSTAGRAM')}</SmallText>
                        </>
                      )}
                    </div>
                    {!heroText ? (
                      <div>
                        <SmallText
                          className="text-lower-case"
                          onClick={() =>
                            window.open(`${heroInfoText?.mailLink}`)
                          }
                        >
                          {heroInfoText?.mail}
                        </SmallText>
                        <SmallText
                          onClick={() =>
                            window.open(
                              `https://www.google.com/maps/place/DELTA+PRODUCTIONS+ApS/@55.675697,12.5941202,21z/data=!4m6!3m5!1s0x46525376e2406ecb:0x330ed93538ead0f8!8m2!3d55.6757197!4d12.5941303!16s%2Fg%2F11k9z6ghtf?entry=ttu`,
                              '_blank'
                            )
                          }
                        >
                          {currentLanguage === 'da'
                            ? heroInfoText?.addressda || heroInfoText?.address
                            : heroInfoText?.address}
                        </SmallText>
                        <SmallText
                          onClick={() =>
                            window.open(`${heroInfoText?.instagramLink}`)
                          }
                        >
                          {heroInfoText?.instagram}
                        </SmallText>
                      </div>
                    ) : (
                      <></>
                    )}
                  </>
                ) : (
                  <>
                    {!heroText ? (
                      <SmallText onClick={openText}>
                        [{t('READ MORE')}]
                      </SmallText>
                    ) : (
                      <></>
                    )}
                  </>
                )}
              </InfoRow>
            </div>
          </AddressRow>
        </BackgroundHeader>
      </HeroSection>
    </>
  )
}
