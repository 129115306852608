import  {http}  from "../utils";

export function getPosts() {
    return http.get('/node')
}

export function getPostsById(id) {
   return http.get(`/node?path=/${id}`)
}

export function adminInfo(){
    return http.get(`/post/2107`)
}

export function getCountry(){
    return http.get('https://api.ipregistry.co/?key=kqmriwy8zxe0mtgy')
}