import React from 'react'
import { styled } from 'styled-components'
import { useTranslation } from 'react-i18next'

const ListWrapper = styled.div`
  position: absolute;
  top: 4px;
`
const ListBox = styled.ul`
  list-style: none;
`
const SmallTextWhite = styled.p`
  font-size: 10px;
  line-height: 12px;
  margin-bottom: 0px !important;
  font-weight: 500;
  height: 60%;
`
function List(props) {
  const { heroTextActive, heroInfoText } = props

  const { t } = useTranslation()

  const currentLanguage = localStorage.getItem('i18nextLng')

  const clientsData =
    currentLanguage === 'da' ? heroInfoText.clientsDa : heroInfoText.clients

  const servicesData =
    currentLanguage === 'da' ? heroInfoText.servicesDa : heroInfoText.services

  return (
    <>
      <div
        className={`position-relative ${
          heroTextActive ? 'list_position' : ''
        } animated_list`}
      >
        <ListWrapper>
          <ListBox>
            {servicesData?.map((curr, index) => {
              return (
                <li key={index} className="list_insider">
                  <SmallTextWhite className="pointer-special">
                    {curr?.value}
                  </SmallTextWhite>
                </li>
              )
            })}
          </ListBox>

          <ListBox>
            {clientsData?.map((curr, index) => {
              return (
                <li key={index} className="list_insider">
                  <SmallTextWhite className="pointer-special">
                    {curr?.value}
                  </SmallTextWhite>
                </li>
              )
            })}
          </ListBox>
          {/* <ListBox>
            {list2.map((curr, index) => {
              return (
                <li key={index} className="list_insider">
                  <SmallTextWhite>{curr.listitems}</SmallTextWhite>
                </li>
              )
            })}
          </ListBox> */}
          <ListBox>
            <SmallTextWhite>{t('CONTACT')}</SmallTextWhite>
            <li className="list_insider">
              <SmallTextWhite className="pointer-special">
                {heroInfoText?.mail}
              </SmallTextWhite>
            </li>

            <li className="list_insider">
              <SmallTextWhite
                className="pointer-special"
                onClick={() => window.open(`${heroInfoText?.mailLink}`)}
              >
                {heroInfoText?.contact}
              </SmallTextWhite>
            </li>
          </ListBox>

          <ListBox>
            <SmallTextWhite>{t('FOLLOW')}</SmallTextWhite>

            <li
              className="list_insider"
              onClick={() => window.open(`${heroInfoText?.instagramLink}`)}
            >
              <SmallTextWhite className="pointer-special">
                INSTAGRAM
              </SmallTextWhite>
            </li>

            <li
              className="list_insider"
              onClick={() => window.open(`${heroInfoText?.linkedinLink}`)}
            >
              <SmallTextWhite className="pointer-special">
                LINKEDIN
              </SmallTextWhite>
            </li>
          </ListBox>
        </ListWrapper>
      </div>
    </>
  )
}

export default List
