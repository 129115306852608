import { styled } from 'styled-components'
import ReactPlayer from 'react-player'


const VideoContainer = styled.div`
  width: ${(props) => props.width || '640px'};
  height: ${(props) => props.height || '360px'};
`

const LazyVideoPlayer = (props) => {
  const {
    url,
    videoWidth,
    videoHeight,
    isPlay,
    setplay,
    playerRef,
    handleDuration,
    handleVideoReady,
    handlePlay,
    windowwidth
  } = props

  const containerheight=videoHeight

  const containerwidth=videoWidth

  const additionalHeight=windowwidth < 480 ? 200:0

  const additionalWidth=windowwidth < 480 ? 20:0

  const loadingPostion={position:'absolute',top:`${videoHeight/2+additionalHeight}px`,left:`${videoWidth/2-additionalWidth}px`}

  return (
    <>
      <VideoContainer height={`${containerheight}px`} width={`${containerwidth}px`}>
    
       <ReactPlayer
        url={url}
        width={`${videoWidth}px`}
        height={`${videoHeight}px`}
        controls={true}
        playing={isPlay}
        onStart={setplay}
        ref={playerRef}
        onDuration={handleDuration}
        style={{cursor: 'pointer'}}
        onReady={handleVideoReady}
        onPlay={handlePlay}
        windowwidth={windowwidth}
        />
        {/* {!isVideoLoaded && <VideoBox ><Loading position={loadingPostion}/></VideoBox>} */}

      </VideoContainer>
    </>
  )
}

export default LazyVideoPlayer
